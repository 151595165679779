import React from 'react';
import styled from 'styled-components';
import {Select} from 'was-react-components/dist';
import {TSelectItem} from 'was-react-components/dist/components/ui/select/types';

const Wrapper = styled.div`
  display: grid;
`;

const SelectWrapper = styled.div`
  padding: 10px 0;
`;

const H3 = styled.h3`
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: var(--dark-grey);
  margin: 0;
`;

type Props = {
  header: string;
  selectArray:  TSelectItem[];
  selectedValue: string;
  selectFn: (val: string) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const CustomSelectView: React.FC<Props> = ({header, selectArray, selectedValue, selectFn}: Props) => {
  return (
    <Wrapper>
      <H3>{header}</H3>
      <SelectWrapper>
        <Select items={selectArray} selectedValue={selectedValue}
          selectFn={selectFn}/>
      </SelectWrapper>
    </Wrapper>
  );
};
