import {createGlobalStyle} from 'styled-components';



export const GlobalStyles = createGlobalStyle`
  :root {
    --light-grey: #F8FAF8;
    --dark-blue: #14192D;
    --blue: #3587AA;
    --light-blue: #38ABFF;
    --grey: #DFDFDF;
    --dark-grey: #6D6D6D;
    --blue-grey: #EDF6F8;
    --dark-blue-wrc: #3587AA;
    --dark-blue-wrc-rgb: 53, 135, 170;
    --light-grey-wrc: #DFDFDF;
    --purple-wrc: #5673EC;
    --purple-wrc-rgb: 86, 115, 236;
    --main-color-wrc: #3587AA;;
    --main-color-wrc-rgb: 53, 135, 170;
  }
  
  body {
    margin: 0;
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: normal;
    font-style: normal;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/Montserrat-Regular.otf') format('opentype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    font-style: normal;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/Montserrat-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/Montserrat-SemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/Montserrat-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: normal;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/OpenSans-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/OpenSans-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/OpenSans-Semibold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: normal;
    src: url('https://cdn.web-ar.studio/libs/platform/fonts/OpenSans-Light.ttf') format('truetype');
  }
`;
