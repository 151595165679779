import React from 'react';
import {TSelectItem} from 'was-react-components/dist/components/ui/select/types';
import {CustomSelectView} from './custom-select.view';

type Props = {
  header: string;
  selectArray: TSelectItem[];
  selectedValue: string;
  selectFn: (val: string) => void;
};

/**
 * @return {React.FC<Props>}
 **/
export const CustomSelect: React.FC<Props> = ({header, selectArray, selectedValue, selectFn}: Props) => {
  return (
    <CustomSelectView header={header} selectArray={selectArray} selectedValue={selectedValue} selectFn={selectFn}/>
  );
};
