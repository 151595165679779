import {E_ROTATION, t} from 'shared';
import {TSelectItem} from 'was-react-components/dist/components/ui/select/types';

export const directionSelectItems = (): TSelectItem[] => [
  {
    name: t('Clockwise'),
    value: E_ROTATION.CLOCKWISE
  },
  {
    name: t('Counterclockwise'),
    value: E_ROTATION.COUNTERCLOCKWISE
  },
];

