import {TSelectItem} from 'was-react-components/dist/components/ui/select/types';
import {E_UNITS} from 'shared';

export const unitsSelectItems = (): TSelectItem[] => [
  {
    name: 'mm',
    value: E_UNITS.MM
  },
  {
    name: 'cm',
    value: E_UNITS.CM
  },
  {
    name: 'm',
    value: E_UNITS.M
  },
];
