import {E_REPEAT} from 'shared/constants';
import {TSelectItem} from 'was-react-components/dist/components/ui/select/types';
import {t} from 'shared';

export const repeatSelectItems = (): TSelectItem[] => [
  {
    name: t('No repetitions'),
    value: E_REPEAT.NO_REPEAT
  },
  {
    name: t('Looped'),
    value: E_REPEAT.REPEAT
  },
];

